import P_gim from './../imgs/P_gim.jpeg';
import P_coc from './../imgs/P_coc.jpeg';
import P_com from './../imgs/P_com.jpeg';
import P_con from './../imgs/P_con.jpeg';
import P_jar from './../imgs/P_jar.jpeg';
import P_dorm from './../imgs/P_dorm.jpeg';
import P_pat from './../imgs/P_pat.jpeg';
import P_sala from './../imgs/P_sala.jpeg';

const plants = [
    {
        id: 'P_Gim',
        title: 'Gimansio',
        img: P_gim,
    },
    {
        id: 'P_Dorm',
        title: 'Dormitorios',
        img: P_dorm,
    },
    {
        id: 'P_Jar',
        title: 'Jardín',
        img: P_jar,
    },
    {
        id: 'P_Con',
        title: 'Consultorios',
        img: P_con,
    },
    {
        id: 'P_Pat',
        title: 'Patio',
        img: P_pat,
    },
    {
        id: 'P_Sala',
        title: 'Sala de reuniones',
        img: P_sala,
    },
    {
        id: 'P_Coc',
        title: 'Cocina',
        img: P_coc,
    },
    {
        id: 'P_Com',
        title: 'Comedor',
        img: P_com,
    },
];

export default plants;
