export const aboutUsTitle = '¿Quiénes somos?';
export const aboutUs = [
    'CENTERAVID, fue creada con el fin de dar asistencia terapéutica a la población adulta hombres a partir de 18 años hasta los 65 años de edad, con problemas de drogodependencia y a sus familias, además de tener actividades en los aspectos básicos que propone la OMS. Y LA O.P.S. En los campos de prevención, asistencia y rehabilitación, así como también en el ámbito de reinserción social, educativo, laboral y familiar',
    '"Porque solo podemos conservar lo que tenemos en la medida en que compartamos con otras personas"',
];
export const visionTitle = 'Visión';
export const vision = [
    'CENTERAVID, es un centro de tercer nivel de atención, especializado en la atención a personas con consumo problemático de alcohol y otras drogas.',
    'Basados en un principio de comunidad terapéutica brinda un tratamiento integral a varones mayores de edad afectados por el consumo de alcohol, sicotrópicos, estupefacientes y otras sustancias que generan dependencia, mediante diagnóstico, tratamiento, rehabilitación y reinserción familiar, social, educativo y laboral del usuario.',
];
export const misionTitle = 'Misión';
export const mision = [
    'CENTERAVID, es un Centro especializado en el tratamiento de las adicciones, cuenta con talento humano altamente calificado, que con responsabilidad y calidad moral brinda a sus usuarios la posibilidad de un cambio en su estilo de vida, logrando entender que es la enfermedad de la adicción y las consecuencias que ella trae, a su vida y a la de sus seres queridos.',
    'Brindando herramientas para la consecución de habilidades que le permitan detener su consumo y mejorar su adaptación al medio social.',
];
