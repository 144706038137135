import { Outlet } from 'react-router-dom';
import NavBar from './../../components/navbar/NavBar';
import Footer from './../../components/footer/Footer';

const Layout = () => {
    return (
        <>
            <NavBar />
            <Outlet />
            <Footer />
        </>
    );
};
export default Layout;
