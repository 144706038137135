import M_isa from './../imgs/M_isa.jpeg';
import M_diana from './../imgs/M_diana.jpeg';
import M_isma from './../imgs/M_isma.jpeg';
import T_eduardo from './../imgs/T_eduardo.jpg';
import M_fabricio from './../imgs/M_fabricio.jpg';
import M_edwin from './../imgs/M_edwin.jpeg';
import M_sady from './../imgs/M_sady.jpeg';
import M_maria from './../imgs/M_maria.jpeg';
import M_rosa from './../imgs/M_rosa.jpeg';

const medicalBody = [
    {
        id: 'M_Isa',
        name: 'Dra. Isabel Padilla',
        role: 'Directora Técnica',
        img: M_isa,
    },
    {
        id: 'M_Diana',
        name: 'Lcda. Diana Auquilla',
        role: 'Directora General',
        img: M_diana,
    },
    {
        id: 'M_Isma',
        name: 'Ismael Sarmiento',
        role: 'Psicólogo Clínico',
        img: M_isma,
    },
    {
        id: 'M_Edwin',
        name: 'Edwin Baculima',
        role: 'Psicólogo Clínico',
        img: M_edwin,
    },
    {
        id: 'M_Fabricio',
        name: 'Fabricio Brito',
        role: 'Psicólogo Clínico',
        img: M_fabricio,
    },
    {
        id: 'M_Eduardo',
        name: 'Eduardo Rivas',
        role: 'Terapista Vivencial',
        img: T_eduardo,
    },
    {
        id: 'M_Sady',
        name: 'Dra. Sady Tello',
        role: 'Psiquiatra',
        img: M_sady,
    },
    {
        id: 'M_Maria',
        name: 'Maria Segarra',
        role: 'Auxiliar de enfermería',
        img: M_maria,
    },
    {
        id: 'M_Rosa',
        name: 'Rosa Arias',
        role: 'Área de cocina',
        img: M_rosa,
    },
];

export default medicalBody;
