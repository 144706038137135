const numbers = [
    {
        id: 'N_Diana',
        number: '0995966980',
    },
    {
        id: 'N_Isa',
        number: '0988013887',
    },
];

export default numbers;
